// Generated by Framer (b75150a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./gDb_RAu2f-0.js";

const cycleOrder = ["vG58QzWpq"];

const serializationHash = "framer-IBAAX"

const variantClassNames = {vG58QzWpq: "framer-v-87rx9z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, text, width, ...props}) => { return {...props, doFoe_HW4: text ?? props.doFoe_HW4 ?? "This guide will walk you through the installation process of Radius, our cutting-edge software designed to revolutionize your data analytics."} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, doFoe_HW4, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "vG58QzWpq", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-87rx9z", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"vG58QzWpq"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TGF0by1yZWd1bGFy", "--framer-font-family": "\"Lato\", \"Lato Placeholder\", sans-serif", "--framer-font-size": "24px", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "1.6em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-cbbc921d-c093-4c69-b545-51f569053670, rgb(171, 171, 174)))"}}>This guide will walk you through the installation process of Radius, our cutting-edge software designed to revolutionize your data analytics.</motion.p></React.Fragment>} className={"framer-1a5epna"} fonts={["GF;Lato-regular"]} layoutDependency={layoutDependency} layoutId={"mKWsOfsKX"} style={{"--extracted-r6o4lv": "var(--token-cbbc921d-c093-4c69-b545-51f569053670, rgb(171, 171, 174))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={doFoe_HW4} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IBAAX.framer-1n0f1tv, .framer-IBAAX .framer-1n0f1tv { display: block; }", ".framer-IBAAX.framer-87rx9z { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 72px 0px; position: relative; width: 744px; }", ".framer-IBAAX .framer-1a5epna { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-IBAAX.framer-87rx9z { gap: 0px; } .framer-IBAAX.framer-87rx9z > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-IBAAX.framer-87rx9z > :first-child { margin-left: 0px; } .framer-IBAAX.framer-87rx9z > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 149
 * @framerIntrinsicWidth 744
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"doFoe_HW4":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergDb_RAu2f: React.ComponentType<Props> = withCSS(Component, css, "framer-IBAAX") as typeof Component;
export default FramergDb_RAu2f;

FramergDb_RAu2f.displayName = "paragraph container large";

FramergDb_RAu2f.defaultProps = {height: 149, width: 744};

addPropertyControls(FramergDb_RAu2f, {doFoe_HW4: {defaultValue: "This guide will walk you through the installation process of Radius, our cutting-edge software designed to revolutionize your data analytics.", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramergDb_RAu2f, [{explicitInter: true, fonts: [{family: "Lato", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxw6XweuBCY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})